import styled, { css } from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const GuestsContainer = styled.div(
    ({ theme }) => css`
        margin: 0 ${theme.spacing.medium};

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            margin: ${theme.spacing.xSmall} ${theme.spacing.large};
        }
    `,
)
