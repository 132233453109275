import React, { FC, useCallback, useState } from 'react'

import { MIN_ADULTS, MIN_CHILDREN } from './constants'
import GuestCountersContainer from './guest-counters-container'
import { GuestPickerExclusiveProps, GuestPickerPassThroughProps } from './types'

type Props = GuestPickerPassThroughProps & {
    component: GuestPickerExclusiveProps['modalComponent']
}

const Modal: FC<Props> = ({
    component: Component,
    isOpen,
    onOpen,
    onClose,
    adultsCount: initialAdultsCount,
    childrenCount: initialChildrenCount,
    onAdultsCountChange,
    onChildrenCountChange,
    ...restProps
}) => {
    const { minAdults, minChildren } = restProps

    const [adultsCount, setAdultsCount] = useState(initialAdultsCount)
    const [childrenCount, setChildrenCount] = useState(initialChildrenCount)

    const _handleClear = useCallback(() => {
        setAdultsCount(minAdults ?? MIN_ADULTS)
        setChildrenCount(minChildren ?? MIN_CHILDREN)
    }, [minAdults, minChildren])

    const _handleReset = useCallback(() => {
        setAdultsCount(initialAdultsCount)
        setChildrenCount(initialChildrenCount)
    }, [initialAdultsCount, initialChildrenCount])

    const _handleSubmit = useCallback(() => {
        onAdultsCountChange(adultsCount)
        onChildrenCountChange(childrenCount)
    }, [adultsCount, childrenCount, onAdultsCountChange, onChildrenCountChange])

    return (
        <Component
            {...restProps}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            onClear={_handleClear}
            onReset={_handleReset}
            onSubmit={_handleSubmit}
            adultsCount={adultsCount}
            childrenCount={childrenCount}
            onAdultsCountChange={setAdultsCount}
            onChildrenCountChange={setChildrenCount}
        >
            <GuestCountersContainer
                {...restProps}
                adultsCount={adultsCount}
                childrenCount={childrenCount}
                onAdultsCountChange={setAdultsCount}
                onChildrenCountChange={setChildrenCount}
            />
        </Component>
    )
}

export default Modal
